import {useObject} from "react-firebase-hooks/database";
import {lastTriggeredref,} from "../FBServices";
import {LastTrig} from "../styled";

function LastTriggered(){
    const [snapshot, loading, error] = useObject(lastTriggeredref);

    return (
            <LastTrig>
                {error && <strong>Error: {error}</strong>}
                {loading && <span>Value: Loading...</span>}
                {snapshot && <span>{snapshot.val()}</span>}
            </LastTrig>
    )
}
export default LastTriggered;