import styled from "styled-components";


export const RowDivHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: fit-content; 
`;

export const RowDivFooter = styled.footer`
  display: flex;
  background-color: aliceblue;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 5%;
  position: fixed;
  width: 100%;
  bottom: 0%;
`;

export const Col = styled.div`
flex: ${(props) => props.size};
  text-decoration-line: none;
  text-transform: uppercase;
  font-family:'Roboto' ;
  font-size: calc(1.3em + 0.6vw);
  text-align: center;
  line-height:calc((1.3em + 0.6vw)* .15 );
  background-color: aliceblue;
  color: darkblue;
  flex-grow: 1;
`;

export const LastTrig = styled.p`
  text-align: center;
  font-size: calc(1.3em + 0.6vw);
  font-family:'Roboto' ;
  color: red;
`;

export const TrigList = styled.div`
  flex-direction: column;
  font-size: calc(1.3em + 0.6vw);
  text-align: center;
  line-height:calc((1.3em + 0.6vw)* 1.5 );
  background-color: white;
  overflow: scroll;
  margin-bottom: 5%;
`;

export const ListItem = styled.div`
  justify-content: space-between;
  font-family:'Roboto';
`;

export const PrettyField = styled.div`
  flex-grow: 3;
`;

export const PrettyButton = styled.button`
    flex-grow: 2;
    `;