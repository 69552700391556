
import {ListItem, TrigList, PrettyButton} from "../styled";

import {useList} from "react-firebase-hooks/database";
import FBServices, {dbRef} from "../FBServices";

function TriggerList(){
        const [snapshots, loading, error] = useList(dbRef);
        return(
            <TrigList>

                {error && <strong>Error: {error}</strong>}
                {loading && <span>List: Loading...</span>}
                {!loading && snapshots && (
                    <div>
                            {snapshots.map((v) => (
                                <ListItem id={v.key} key={v.key}>{v.val()} <PrettyButton onClick={ () =>
                                    FBServices.deleteEntry(v.key)
                                }>remove</PrettyButton>
                                </ListItem>
                            ))}
                    </div>
                )}
            </TrigList>
        );


}

export default TriggerList;