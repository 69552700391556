import { initializeApp } from 'firebase/app';
import {
    getDatabase,
    ref,
    push,
    set,
    remove,
    onValue } from 'firebase/database';


const firebaseConfig = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: "https://case-studies-330815-default-rtdb.firebaseio.com",
    projectId: process.env.FIREBASE_PROJECT_ID,
    appId: "1:114703023451:web:5261baff280565dc5b2e7e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
export const dbRef = ref(db,'triggers');
export const lastTriggeredref = ref(db,'last-triggered');
class FBServices {
// Create
    createEntry = (newTriggerWord) => {
        const newDbRef = push(dbRef);
        set(newDbRef,
            newTriggerWord.trim()

        )
            .then(() =>
                console.log(newDbRef.key.toString()));

    }
// Read
    readEntries = (snapshot) => {
        return onValue(dbRef, (snapshot));
    }
// Update
    updateEntry = (entryKey, newTriggerValue) => {
        const entryRef = ref(db, 'triggers/' + entryKey);
        console.log('update Entry: ' + entryRef.toString());
        set(entryRef, newTriggerValue)
            .then(() =>
                console.log(entryRef.key.toString()));

    }
// Delete
    deleteEntry = (entryKey) => {
        const entryRef = ref(db, 'triggers/' + entryKey);
        remove(entryRef)
            .then(() => console.log("Data Removed"))
    }

// Escape special character '+'
    escapePlus = (newTriggerWord) =>{
        const result = newTriggerWord.match('\+/g');
            if(result){
                console.log(result.start)

            };



    }

    pullKey = (triggerWord) => {
        dbRef.orderByValue().on('value', (snapshot) => {
            snapshot.forEach((data) => {
                console.log('The ' + data.key + ' dinosaur\'s score is ' + data.val());
            });
        });

    }
}
export default new FBServices();
