import {RowDivHeader} from "../styled";
import {Col} from '../styled';
let className = 'row';
const HeaderNav = () => (
    <RowDivHeader className={className}>
        <Col size={1}>
            <h2 id="home-tab" to="/">TriggerWord</h2>
        </Col>
    </RowDivHeader>

);

export default HeaderNav;

