import React from 'react';
import * as ReactDomClient from 'react-dom/client';
import './index.css';
import App from './App';

const container = document.getElementById('root');
const root = ReactDomClient.createRoot(container);
//initial Render

root.render(<App name="TriggerWord WebClient" />);

//During updates
root.render(<App callback={()=> console.log("rendered")} />);
