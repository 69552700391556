import HeaderNav from "./components/HeaderNav";
import LastTriggered from "./components/LastTriggered";
import TriggerList from "./components/TriggerList";
import Footer from "./components/Footer";

function App({callback}) {
    return (
        <div ref={callback}>
            <HeaderNav className='header'/>
            <LastTriggered></LastTriggered>
            <TriggerList></TriggerList>
            <Footer className='footer'/>
        </div>
    );
}
export default App;
